import { inject, Injectable, Signal, signal } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { Observable, of, Subject } from 'rxjs';
import { catchError, filter, map, takeUntil, tap } from 'rxjs/operators';

import { CloudType, ProjectHealth, ProjectsApiService, ProjectStatus } from '@api-open';
import { AlertifyService } from '@tk/shared/lib';

export type Project = {
  id: number;
  isVirtualCluster: boolean;
  hasKubeConfigFile: boolean;
  health: ProjectHealth;
  parentProjectId?: number | null;
  isLocked: boolean;
  totalHourlyCost?: number;
  name: string | null;
  status: ProjectStatus;
  expiredAt: string | null;
  organizationName: string | null;
  cloudType?: CloudType;
};

@Injectable()
export class ProjectDtoService {
  private _projectDto = signal<Project | null>(null);

  private readonly projectReset = new Subject<void>();

  private readonly projectsApiService = inject(ProjectsApiService);

  get projectDto(): Signal<Project | null> {
    return this._projectDto;
  }

  presetProject(dto: Project): void {
    this._projectDto.set(dto);
  }

  reloadProject(): void {
    const project = this._projectDto();
    if (!project) {
      Sentry.captureException(new Error(`for this operations project can't be empty`));
      return;
    }
    this.fetchProject(project.id)
      .pipe(takeUntil(this.projectReset.asObservable()))
      .subscribe((project) => {
        this._projectDto.set(project);
      });
  }

  resetProjectData(): void {
    this._projectDto.set(null);
    this.projectReset.next();
  }

  isVirtualCluster(projectId: number): Observable<boolean> {
    const project = this._projectDto();

    if (!project || (project && project.id !== projectId)) {
      return this.fetchProject(projectId).pipe(
        tap((project) => {
          if (project) {
            this._projectDto.set(project);
          } else {
            throw new Error(`Project not found: ${projectId}`);
          }
        }),
        filter((project): project is Project => project !== null),
        map(({ isVirtualCluster }) => isVirtualCluster!),
        catchError((err) => {
          inject(AlertifyService).negative(err);
          throw err;
        }),
      );
    }

    return of(project.isVirtualCluster);
  }

  hasKubeInfo(projectId: number): Observable<boolean> {
    return this.fetchProject(projectId).pipe(
      tap((project) => {
        if (project) {
          this._projectDto.set(project);
        } else {
          throw new Error(`Project not found: ${projectId}`);
        }
      }),
      map((projectData) => {
        if (
          projectData &&
          projectData.hasKubeConfigFile &&
          (projectData.health === ProjectHealth.healthy || projectData.health === ProjectHealth.warning)
        ) {
          return true;
        }
        return false;
      }),
      catchError((err) => {
        inject(AlertifyService).negative(err);
        throw err;
      }),
    );
  }

  isProjectExists(projectId: number): Observable<boolean> {
    if (!this._projectDto() || projectId !== this._projectDto()?.id) {
      return this.fetchProject(projectId).pipe(
        tap((project) => {
          if (project) {
            this._projectDto.set(project);
          } else {
            throw new Error(`Project not found: ${projectId}`);
          }
        }),
        map((project) => !!project),
        catchError((err) => {
          inject(AlertifyService).negative(err);
          throw err;
        }),
      );
    }
    return of(true);
  }

  private fetchProject(projectId: number): Observable<Project | null> {
    return this.projectsApiService.projectsList({ id: projectId }).pipe(
      map((responce) => {
        const { data } = responce;
        if (data && data.length > 0) {
          const [projectDto] = data;
          return projectDto;
        }
        return null;
      }),
    );
  }
}
